import React from 'react'
import Header from '../components/Header/index'
import SEO from '../components/SEO/seo'
import ConfirmBooking from '../components/BookingForm/Form-Steps/confirmBooking'

const ConfirmBook = () => (
	<>
		<Header />
		<SEO title='Confirm Booking' />
		<ConfirmBooking />
	</>
)

export default ConfirmBook
