import React from 'react';
import './index.css';
import NavBar from './Nav-Bar';

const Header = () => (
  <>
    <NavBar />
  </>
);

export default Header;
