import React, { useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import {
  Collapse,
  NavItem,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
} from "reactstrap";
import "./index.css";
import { LanguageContext } from "../../../lang";
import { isBrowser, langLink, getPrefix } from "../../../utils/general";
import ReactFlagsSelect from "react-flags-select";
import { useMediaQuery } from "react-responsive";

const parseLang = (l) => {
  switch (l) {
    case "GB":
      return "en";
    case "DE":
      return "";
    default:
      return "";
  }
};

const getDefaultLang = () => {
  switch (getPrefix()) {
    case "en":
      return "GB";
    default:
      return "DE";
  }
};

const changeLanguage = l => {
  let lng = parseLang(l);
  if (isBrowser()) {
    let str = "/";
    let p = window.location.pathname.split("/");
    if (window.location.pathname === "/") {
      str = lng ? `/${lng}` : "/";
    } else if ((p[1] && p[1] === "en") || (p[1] && p[1] === "gm")) {
      str = window.location.pathname.replace(
        p.length === 2 ? `/${p[1]}` : `/${p[1]}/`,
        lng ? `/${lng}/` : "/"
      );
    } else {
      str = lng? `/${lng}${window.location.pathname}`: window.location.pathname;
    }
    window.location.assign(str + (window.location.search || ""));
  }
};

const NavBar = () => {
  const lang = useContext(LanguageContext);
  const {
    translations: { navbar },
  } = useContext(LanguageContext);
  const {
    airportText,
    taxiEindhoven,
    aboutUsText,
    myAccountText,
    signoutText,
    contact,
    loginText,
    taxiRate,
    airportTransportation,
  } = navbar;
  console.log(lang);
  const [dropDown, setDropDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const dropToggle = () => {
    setDropDown(!dropDown);
  };
  useEffect(() => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : {};
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem("token"))
      : {};

    token || sessionToken ? setIsLoggedIn(true) : null;
  });
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });

  return (
    <header id="ts-header" className="fixed-top">
      <div className="container">
        <Navbar className="my-navbar pt-4" dark expand="lg">
          <NavbarBrand tag={Link} to={`${langLink()}`} className="">
            <img
              className="img-fluid logo"
              src={require("../../../images/schnellein-logo.png")}
            />
          </NavbarBrand>
          {!isDesktopOrLaptop && (
            <div
              style={{ position: "absolute", right: 100, top: 28, width: 50 }}
            >
              <ReactFlagsSelect
                showSelectedLabel={false}
                defaultCountry={getDefaultLang()}
                countries={["DE", "GB"]}
                customLabels={{
                  GB: "English",
                  DE: "German",
                }}
                className="lang-flags"
                onSelect={changeLanguage}
              />
            </div>
          )}

          <NavbarToggler onClick={toggle} className="text-light" />

          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="">
                <Link
                  className="text-white nav-link navbar-link"
                  to={langLink("airport-transportation")}
                >
                  {airportTransportation}
                </Link>
              </NavItem>
              <NavItem className="">
                <Link
                  className="text-white nav-link navbar-link"
                  to={langLink("taxi-to-neumunster")}
                >
                  {taxiEindhoven}
                </Link>
              </NavItem>
              {/* <NavItem className="">
                <Link
                  className="text-white nav-link navbar-link"
                  to={langLink("taxi-rates")}
                >
                  {taxiRate}
                </Link>
              </NavItem> */}
              <NavItem className="">
                <Link
                  className="text-white nav-link navbar-link"
                  to={langLink("about")}
                >
                  {aboutUsText}
                </Link>
              </NavItem>
              <NavItem className="">
                <Link
                  className="text-white nav-link navbar-link"
                  to={langLink("contact-us")}
                >
                  {contact}
                </Link>
              </NavItem>

              {isLoggedIn ? (
                <NavItem className="">
                  <Link
                    className="text-white nav-link navbar-link"
                    to={langLink("my-account")}
                  >
                    {myAccountText}
                  </Link>
                </NavItem>
              ) : null}
              {/* <NavItem className="">
                {isLoggedIn ? (
                  <Link
                    className="text-white nav-link navbar-link"
                    to={langLink("signout")}
                  >
                    {signoutText}
                  </Link>
                ) : (
                    <Link
                      className="text-white nav-link navbar-link"
                      to={langLink("login")}
                    >
                      {loginText}
                    </Link>
                  )}
              </NavItem> */}
              {isDesktopOrLaptop && (
                <ReactFlagsSelect
                  defaultCountry={getDefaultLang()}
                  countries={["DE", "GB"]}
                  customLabels={{
                    GB: "English",
                    DE: "German",
                  }}
                  className="lang-flags"
                  onSelect={changeLanguage}
                />
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default NavBar;
